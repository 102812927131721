<template>
  <div class="page-accounting container">
    <div v-for="(app, setting_index) in settings" :key="setting_index">
      <b-card class="mb-2">
        <strong>{{ app.label }}</strong>
        <div v-for="(setting, settingKey, setting_index) in app.settings" :key="setting_index">
          <div v-for="(s, sKey, setting_index) in setting" :key="setting_index">
            <b-row class="pb-2" v-if="s.type === 'String'">
              <b-col class="d-flex align-items-center" sm="2">
                <label class="mb-0" :for="`input-${s.key}`">{{ s.label }}:</label>
              </b-col>
              <b-col sm="10">
                <b-form-input
                  :id="`input-${s.key}`"
                  style="border: 1px solid grey"
                  class="p-2"
                  v-model="app.settings[settingKey][sKey]['value']"
                  type="text">
                </b-form-input>
              </b-col>
            </b-row>
            <b-row class="pb-2" v-if="s.type === 'Link' && settingKey === 'outh2'">
              <b-col class="d-flex align-items-center" sm="2">
                <label class="mb-0" :for="`input-${s.key}`">{{ s.label }}:</label>
              </b-col>
              <b-col sm="10">
                <b-button @click="openAuthWindow(app.settings[settingKey][sKey]['value'])">Login</b-button>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import MtApi from '@/agGridV2/helpers/mt-api.helper'
export default {
  data() {
    return {
      settings: []
    }
  },
  async mounted() {
    this.settings = await MtApi.applicationsSettings()
  },
  methods: {
    openAuthWindow(authUrl) {
      window.location.href = authUrl
    }
  }
}
</script>
